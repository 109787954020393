<template>
  <div class="request-chat" v-if="chat">
    <div class="page-header">
      <div class="page-header--title">
        <h1 class="title">Conversation avec {{ chat.initiator.firstname }} {{ chat.initiator.lastname }}</h1>
        <h2 class="subtitle">{{ chat.initiator.email }} - Démarrée le {{ formatDate(chat.created_at, "EEEE dd LLLL uuuu 'à' HH':'mm") }}</h2>
      </div>
      <!-- <div class="page-header--actions">
        <b-button color="success" type="is-danger" @click="endChat">
          <i class="fas fa-times"></i> Fermer la conversation
        </b-button>
      </div> -->
    </div>
    <div class="chat-messages">
      <chat-message v-for="(message, i) in chat.messages" :key="i"
        :message="message" :request="chat"
      ></chat-message>
    </div>

    <div v-if="isTyping">typing...</div>

    <div class="chat-redactor">
      <b-loading :active.sync="socketDisconnected" :is-full-page="false"></b-loading>
      <!-- <b-field class="field-transfer">
        <b-tooltip label="Transférer la conversation" type="is-black">
          <a class="button is-secondary" @click="transfer()">
            <b-icon icon="fas fa-random"></b-icon>
          </a>
        </b-tooltip>
      </b-field> -->

      <b-field class="field-file">
        <b-upload v-model="file" :input="handleFileUpload()">
          <b-tooltip label="Envoyer un fichier" type="is-black">
            <a class="button is-secondary">
              <b-icon icon="fas fa-paperclip"></b-icon>
            </a>
          </b-tooltip>
        </b-upload>
        <div class="file-uploaded" v-if="file">
          <b-tooltip :label="file.name" type="is-black">
            <div class="filename">{{ file.name }}</div>
          </b-tooltip>
          <b-tooltip label="Retirer ce fichier" type="is-danger">
            <div class="file-delete">
              <a class="delete"></a>
            </div>
          </b-tooltip>
        </div>
      </b-field>
      <b-field class="field-message" type="is-secondary">
        <b-input type="textarea" v-model="message" @focus="typing(true)"
          @blur="typing(false)" rows="2" class="field-message-input"
          icon-right="fas fa-paper-plane" :icon-right-clickable="message !== ''"
          @icon-right-click="send()" :disabled="loading"></b-input>
      </b-field>
    </div>
  </div>
</template>

<script>
import { parseISO, format } from 'date-fns'
import fr from 'date-fns/locale/fr'
import { mapGetters } from 'vuex'
import { http } from '@/services/api.service'
import { socket } from '@/services/socket.service'

import ChatMessage from './Chat/Message'
import TransferModal from './Chat/TransferModal'

export default {
  components: { ChatMessage },
  computed: {
    ...mapGetters([
      'socketDisconnected',
      'myRequests',
      'user'
    ])
  },
  data () {
    return {
      requestUuid: this.$route.params.uuid,
      chat: null,
      message: '',
      loading: false,
      isTyping: false,
      file: null
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.loadChat(to.params.uuid)
    next()
  },
  mounted () {
    this.loadChat(this.$route.params.uuid)
    this.loadSocketListener()
  },
  methods: {
    loadChat (uuid) {
      http.get(`/chats/${uuid}`).then(({ data }) => {
        // console.log('data', data)
        this.chat = data
        socket.emit('subscribe', { room: `${this.chat.uuid}` })
        this.scrollToBottom()
      }).catch(err => {
        console.log(err)
      })
    },
    formatDate (date, dateFormat) {
      return format(parseISO(date), dateFormat, {
        locale: fr
      })
    },
    typing (isTyping) {
      if (isTyping) {
        socket.emit('chat:typing', { uuid: this.chat.uuid })
      } else {
        socket.emit('chat:stop-typing', { uuid: this.chat.uuid })
      }
    },
    handleFileUpload () {
      // this.file = this.$refs.file.file[0]
    },
    send () {
      this.loading = true
      const formData = new FormData()
      if (this.message) {
        formData.append('content', this.message)
      }

      if (this.file) {
        formData.append('attachment', this.file)
      }

      const headers = {
        Enctype: 'multipart/form-data'
      }
      http.post(`/chats/${this.chat.uuid}/messages`, formData, headers).then(res => {
        socket.emit('chat:send', {
          room: `chat/${this.chat.uuid}`,
          request: this.chat,
          message: res.data
        })
        this.message = null
        this.file = null
        this.chat.messages.push(res.data)
        this.scrollToBottom()
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    },
    transfer () {
      this.$buefy.modal.open({
        parent: this,
        component: TransferModal,
        hasModalCard: true,
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        props: { request: this.chat },
        events: {
          transferred: (created) => {
            this.$buefy.notification.open({
              duration: 4000,
              message: 'transfère réussi !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
            this.$emit('transfer', this.chat)
            this.$router.push('/app/dashbord')
          }
        }
      })
    },
    scrollToBottom () {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
      }, 400)
    },
    loadSocketListener () {
      socket.on('chat:user-typing', () => {
        this.isTyping = true
      })
      socket.on('chat:user-stop-typing', () => {
        this.isTyping = false
      })
      socket.on('chat:new-message', ({ request, message }) => {
        this.chat.messages.push(message)
        this.scrollToBottom()
      })
    },
    endChat () {
      http.post(`/request/${this.chat.uuid}/close`).then(({ data }) => {
        socket.emit('request:closed', { request: data })
        this.$store.commit('SET_MY_REQUESTS', this.myRequests.filter(request => request.uuid !== this.chat.uuid))
        this.$router.push('/app/dashbord')
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.request-chat {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.chat-messages {
  flex-grow: 1;
  margin-bottom: 100px;
}
.chat-redactor {
  background-color: #fff;
  display: flex;
  padding: 0.75rem 0.75rem 0.75rem 0;
  box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.01);
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 260px;
  right: 260px;

  .field {
    margin-left: 0.75rem;
  }
  .field-transfer,
  .field-file {
    .button {
      height: 63px;
      width: 63px;
      font-size: 26px;
    }
  }
  .field-file {
    .file-uploaded {
      display: flex;
      border-color: #dbdbdb;
      border-style: solid;
      border-width: 1px 1px 1px 0;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
    }
    .filename {
      font-size: 10px;
      padding-top: 0;
      padding-bottom: 0;
      max-width: 100px;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
    }
    .file-delete {
      display: block;
      margin-left: 6px;
    }
  }
  .field-message {
    flex-grow: 1;
  }
  .field-message-input {
    ::v-deep  .icon.is-right {
      width: 64px;
      height: 100%;
      svg {
        color: #445877;
        height:100%;
        width:32px;
      }
    }
  }
}
// .btn-sendMessage {

// }
</style>
